<template>
  <mf-loading-dialog :loading="$apollo.loading || loading">
    <v-container class="pb-2 mt-n4" fluid>
      <v-layout>
        <p class="ml-1 page-subtitle">Cadastrar {{ saleTypeMapped }}</p>
        <v-spacer />
      </v-layout>
      <v-card-actions class="px-0 mt-2 mb-1">
        <mf-button outlined color="error" label="Cancelar" @click="$router.push(`/franchises/${clientId}/edit/acoes`)"></mf-button>
        <v-spacer />
        <mf-button color="primary" label=" Inserir produto" icon="add" outlined @click="addProductModal = true"></mf-button>
        <mf-button label="Concluir ação" color="primary" icon="done" :disabled="!products.length" @click="confirm_sale = true"></mf-button>
      </v-card-actions>
      <v-card class="pa-8">
        <v-card-actions>
          <v-spacer />
          <h2 class="mx-4 mb-2">Total: {{ total }}</h2>
        </v-card-actions>
        <v-data-table :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }" :headers="headers" :items="products">
          <template v-slot:[`item.unit_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' ? parseCurrencySms(item.unit_value) : parseCurrencyDefault(item.unit_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.charge_type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' || item.type === 'mercalider' ? (item.charge_type === 'recurring' ? 'Recorrente' : 'Única') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.is_accumulative`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' ? (item.is_accumulative ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.has_order`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'mercashop' ? (item.has_order ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.implantation_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getProductFromType(item.type).hasSetupCost ? parseCurrencyDefault(item.implantation_value) : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.plan`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'mercalider' ? item.plan : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.total_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrencyDefault(item.total_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-btn text color="error" @click="popProduct(item)">Remover</v-btn>
              </v-layout>
            </v-flex>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <add-product-dialog :dialog="addProductModal" :products="products" :availableProducts="listProducts" @close="close" />
    <dialog-confirm-sale v-model="confirm_sale" :is-sale="isSale" :validate-contract-link="true" :disable-button="disableButton" @confirm-action="saveSale" />
  </mf-loading-dialog>
</template>

<script>
import { MUTATION_CREATE_NEW_SALE, MUTATION_CREATE_CROSS, QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT, MUTATION_CREATE_UPSELL } from '@/modules/franchises/graphql'
import { ProductsById, Products } from '@/constants'
import { parseCurrency, productsInformation } from '@/mixin'
import { getSaleTypeByValue } from '@/constants/salesTypes'
export default {
  components: {
    AddProductDialog: () => import('../../../../components/atomic-components/organism/AddProductDialog.vue'),
    DialogConfirmSale: () => import('../../../../components/atomic-components/molecules/DialogConfirmSale')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    clientId: {
      type: String,
      default: () => ''
    },
    saleType: {
      type: String
    }
  },
  data: () => ({
    products: [],
    addProductModal: false,
    loading: false,
    availableProducts: [],
    confirm_sale: false,
    disableButton: false
  }),
  computed: {
    isSale() {
      return ['new_sale', 'cross_sell', 'upsell'].includes(this.saleType)
    },
    saleTypeMapped() {
      return getSaleTypeByValue(this.saleType)?.label
    },
    headers() {
      return [
        {
          text: 'Nome',
          value: 'name',
          align: 'center'
        },
        {
          text: 'Valor unitário',
          value: 'unit_value',
          align: 'center'
        },
        {
          text: 'Quantidade',
          value: 'quantity',
          align: 'center'
        },
        {
          text: 'Tipo de cobrança',
          value: 'charge_type',
          align: 'center'
        },
        {
          text: 'Acumulativo',
          value: 'is_accumulative',
          align: 'center'
        },
        {
          text: 'Valor da implantação',
          value: 'implantation_value',
          align: 'center'
        },
        {
          text: 'Plano',
          value: 'plan',
          align: 'center'
        },
        {
          text: 'Valor total',
          value: 'total_value',
          align: 'center'
        },
        {
          text: '',
          value: 'options',
          align: 'center'
        }
      ]
    },
    total() {
      let totalValue = 0
      this.products.map(item => (totalValue += item?.total_value))
      return this.parseCurrencyDefault(totalValue)
    },
    mutation() {
      switch (this.saleType) {
        case 'new_sale':
          return MUTATION_CREATE_NEW_SALE
        case 'cross_sell':
          return MUTATION_CREATE_CROSS
        case 'upsell':
          return MUTATION_CREATE_UPSELL
        default:
          return ''
      }
    },
    listProducts() {
      let products = Products.map(product => ({
        value: product.id,
        name: product.name
      }))
      switch (this.saleType) {
        case 'new_sale':
          return products
        case 'cross_sell':
          return products.filter(i => {
            if (!this.availableProducts.includes(i.value)) return i
          })
        case 'upsell':
          return this.availableProducts.map(product => {
            return {
              name: ProductsById[product].name,
              value: product
            }
          })
        default:
          return products
      }
    }
  },
  apollo: {
    verifyIfHasNewSale: {
      query: QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT,
      fetchPolicy: 'network-only',
      variables() {
        return {
          franchise_id: this.$route.params.id,
          filters: {
            active: true
          },
          pagination: {
            page: 1,
            pageSize: 9999,
            search: '',
            sort: ['_id'],
            sortDirection: 'ASC'
          }
        }
      },
      update({ franchiseAvailableProducts: { count, data } }) {
        this.availableProducts = data.map(i => i.product_type)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    close() {
      this.addProductModal = false
    },
    popProduct(product) {
      const index = this.products.indexOf(product)
      this.products.splice(index, 1)
    },
    async saveSale({ date, contract_link, ambev_incentive, observation }) {
      this.disableButton = true
      try {
        await this.$apollo.mutate({
          mutation: this.mutation,
          variables: {
            franchise_id: this.clientId,
            sale: {
              type: this.saleType,
              items: this.products,
              created_at: date,
              contract_link,
              ambev_incentive,
              observation
            }
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        this.$alert({
          alert_message: 'Venda inserida com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$router.push(`/franchises/${this.clientId}/edit/acoes`)
        this.disableButton = false
      } catch (err) {
        console.log(err?.message)
        this.disableButton = false
        this.$alert({
          alert_message: 'Falha ao inserir venda',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>
